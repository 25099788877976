import { ResultView, supportsConceptDrift } from "@/domains/monitoring";
import { ResultOf } from "@graphql-typed-document-node/core";
import { AnalysisType, SummarySectionResultFragment, gql } from "@/apis/nannyml";
import { EditSummaryConceptDrift, EditSummaryCovariateShift, EditSummaryDataQuality, EditSummaryPerformance } from "../monitoring/SelectResults";

export const getSummaryQuery = gql(/* GraphQL */ `
    query GetSummary($modelId: Int!) {
      monitoring_model(id: $modelId) {
        id
        problemType
        hasInitialRunCompleted
  
        latestRun {
          ...MonitoringRunStatus
        }
  
        kpm {
          metric
          component
          results(segments: [null]) {
            __typename
            id
            analysisType
            metricName
            ...SummarySectionResult
          }
        }
  
        summaryResults: results(filter: { tags: ["summary"] }) {
          __typename
          id
          analysisType
          ... on TimeSeriesResult {
            metricName
          }
          ...SummarySectionResult
        }
  
        results(filter: { segments: [null] }) {
          ...SummaryResult
        }
      }
    }
  `);

type ModelType = NonNullable<ResultOf<typeof getSummaryQuery>["monitoring_model"]>;
type ResultType = Extract<ModelType["summaryResults"][number], { __typename: "TimeSeriesResult" }>;

export const sections: Record<
  ResultView,
  {
    isSupported?: (model: ModelType) => boolean;
    resultFilter: (results: ResultType) => boolean;
    EditSectionComponent: typeof EditSummaryPerformance;
    flags: Record<string, (model: ModelType) => (result: SummarySectionResultFragment) => boolean>;
  }
> = {
  [ResultView.Performance]: {
    resultFilter: (r) =>
      r.analysisType === AnalysisType.RealizedPerformance || r.analysisType === AnalysisType.EstimatedPerformance,
    EditSectionComponent: EditSummaryPerformance,
    flags: {
      kpm: (model) => (result) => model.kpm.results.some((r) => r.id === result.id),
    },
  },
  [ResultView.ConceptDrift]: {
    isSupported: (model) => supportsConceptDrift(model.problemType),
    resultFilter: (r) => r.analysisType === AnalysisType.ConceptShift,
    EditSectionComponent: EditSummaryConceptDrift,
    flags: {},
  },
  [ResultView.CovariateShift]: {
    resultFilter: (r) => r.analysisType === AnalysisType.FeatureDrift || r.analysisType === AnalysisType.SummaryStats,
    EditSectionComponent: EditSummaryCovariateShift,
    flags: {},
  },
  [ResultView.DataQuality]: {
    resultFilter: (r) => r.analysisType === AnalysisType.DataQuality,
    EditSectionComponent: EditSummaryDataQuality,
    flags: {},
  },
};