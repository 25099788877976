import { Image, Text, Page, Document, StyleSheet, View } from "@react-pdf/renderer";

import { ContentData } from "./Report.utils";

export const PdfReport = ({ reportContent }: { reportContent: ContentData[] }) => {
  const styles = StyleSheet.create({
    page: {
      paddingBottom: 80,
      paddingHorizontal: 20,
      fontFamily: "Helvetica",
    },
    Title: { fontSize: 20, marginBottom: 15, marginHorizontal: 20 },
    Header: { fontSize: 16, marginBottom: 10, marginHorizontal: 20 },
    Paragraph: { fontSize: 12, marginBottom: 7, marginHorizontal: 20 },
    PlotTitle: { fontSize: 10, marginBottom: 7, marginHorizontal: 30 },
    Plot: { marginBottom: 30, marginHorizontal: 30 },
    PlotBackground: { backgroundColor: "rgb(27 28 36)" },
    Logo: { marginLeft: 470, marginTop: 20, marginBottom: 20, width: 50, height: 50 },
  });

  return (
    <Document>
      <Page wrap={true}>
        <Image style={styles.Logo} src={"/nannyml-logo-icon.png"} fixed />
        <View style={styles.page}>
          {reportContent.map((content) => {
            return content.plotOptions ? (
              <View key={content.id} wrap={false} style={styles.Plot}>
                <Text style={styles.PlotTitle}>{content.plotOptions?.plotTitle}</Text>
                <Image style={styles.PlotBackground} src={content.plotOptions?.plotImage}></Image>
              </View>
            ) : (
              <View key={content.id} style={styles[content.label as keyof typeof styles] ?? styles.Paragraph}>
                <Text>{content.data}</Text>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};
