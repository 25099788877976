import { Check, PencilIcon } from "lucide-react";
import { useState } from "react";

import { cn } from "@/lib/utils";

import { TextArea } from "../TextArea";
import { ContentData } from "./Report.utils";

export const TextContent = ({
  parentEditMode,
  content,
  onContentUpdate,
}: {
  parentEditMode: boolean;
  content: ContentData;
  onContentUpdate: (option: ContentData) => void;
}) => {
  const [edit, setEdit] = useState(!content.data);
  const [text, setText] = useState(content.data);

  const editable = parentEditMode && edit;

  const getSettings = () => {
    switch (content.label) {
      case "Title":
        return {
          placeholder: "Report Title",
          className: content.args[0],
          cols: 100,
          rows: 1,
          iconSize: 20,
        };
      case "Header":
        return {
          placeholder: "Sub-title",
          className: content.args[0],
          cols: 100,
          rows: 1,
          iconSize: 17,
        };
      default:
        return {
          placeholder: "Placeholder",
          className: content.args[0],
          cols: 100,
          rows: 5,
          iconSize: 14,
        };
    }
  };

  const openEditMode = () => {
    setEdit(true);
  };

  const closeEditMode = () => {
    setEdit(false);
  };

  const updateText = (newText: string) => {
    setText(newText);

    onContentUpdate({ ...content, data: newText });
  };

  const escapeOnEnter = (key: string) => {
    const isHeader = getSettings().rows === 1;

    if (isHeader) {
      if (key === "Enter") {
        setEdit(false);
      }
    }
  };

  return (
    <div>
      {editable ? (
        <div className="flex w-[100%]">
          <TextArea
            className="w-[90%]"
            rows={content.textOptions?.rows}
            placeholder={content.textOptions?.placeholder}
            onChange={(e) => updateText(e.target.value)}
            onBlur={closeEditMode}
            value={text}
            onKeyDown={(e) => {
              escapeOnEnter(e.key);
            }}
            autoFocus
          />
          <div className="ml-3 w-[10%]">
            <Check />
          </div>
        </div>
      ) : (
        <div className="group flex w-[100%]">
          <label
            style={{ whiteSpace: "pre-wrap" }}
            className={`truncate w-[90%] ${content.textOptions?.className}`}
            onClick={openEditMode}
          >
            {text ? text : content.textOptions?.placeholder}
          </label>
          <div className={cn("ml-3 mt-2 w-[10%] hidden", parentEditMode && !edit ? "group-hover:flex" : "")}>
            <PencilIcon onClick={openEditMode} size={content.textOptions?.iconSize}></PencilIcon>
          </div>
        </div>
      )}
    </div>
  );
};
