import { selectWhere } from "@/lib/typesUtils"
import { useParamsModelId } from "@/routes/useParamsModelId"
import _ from "lodash"
import { useSuspenseQuery } from "@apollo/client";
import { ModelResultsFilter, gql } from "@/apis/nannyml"
import { ResultOf } from "@graphql-typed-document-node/core";

const getModelResultsQuery = gql(/* GraphQL */ `
  query GetModelResults($modelId: Int!, $filter: [ModelResultsFilter!]) {
    monitoring_model(id: $modelId) {
      results(filter: $filter) {
        __typename
        id
        ... on TimeSeriesResult {
          ...ResultPlot
          ...ResultFilter
        }
      }
    }
  }
`);

export type TimeSeriesResultSummary = Extract<ResultOf<typeof getModelResultsQuery>, { __typename: "TimeSeriesResult" }>;


export type PlotOptions = {
    modelId?:number,
    analysisType?:string,
    columnName?:string,
    metricName?:string,
    componentName?:string,
    id?:string,
    layout?:any
    dateRange?:[number, number]
    plotImage?:string
    plotTitle?:string
}

export type TextOptions = {
    placeholder:string,
    className:string,
    cols:number,
    rows:number,
    iconSize:number
}

export type ContentData = {
    id:number
    label:string,
    key:string,
    args:string[],
    data?:string,
    plotOptions?:PlotOptions
    textOptions?:TextOptions
}

export const getSummaryData = (filter:any) => {

  const modelId = useParamsModelId();

  const {
    data,
    error,
} = useSuspenseQuery(getModelResultsQuery, {
    variables: { modelId:modelId},
  });

  const model = data?.monitoring_model;
  if (!model) {
      throw new Error(`Model ${1} not found`);
  }
  if (model.results){
    const allResults = model.results.filter(selectWhere("__typename", "TimeSeriesResult"))?? []

    return allResults.filter(filter)
  }

  return []
}

export const getPlotData = (plotOptions:PlotOptions) => {
    const resultFilter = {
        'analysisTypes': [plotOptions.analysisType],
        'columnNames': plotOptions.columnName?[plotOptions.columnName]:null,
        'metricNames': plotOptions.metricName?[plotOptions.metricName]:null,
        'componentNames': plotOptions.componentName?[plotOptions.componentName]:null
    } as ModelResultsFilter

    if(plotOptions.modelId){
      const {
          data: { monitoring_model: model },
      } = useSuspenseQuery(getModelResultsQuery, {
          variables: { modelId:plotOptions.modelId, filter: { ...resultFilter } },
        });
      const results = model?.results.filter(selectWhere("__typename", "TimeSeriesResult")) ?? [];

      return results
    }
}
