import * as Collapsible from "@radix-ui/react-collapsible";
import _ from "lodash";
import { ChevronDownIcon, ChevronRightIcon, ChevronLeftIcon, GitCommitVerticalIcon } from "lucide-react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RouteObject } from "react-router-dom";

import { SimpleTooltip } from "@/DesignSystem/nanny/SimpleTooltip/SimpleTooltip";
import { cn } from "@/lib/utils";

import { Button } from "./common/Button";

type NavigationContextType = {
  isOpen: (key: string) => boolean | undefined;
  setOpen: (key: string, value: boolean) => void;
};

type KeyContextType = {
  key: string;
  defaultOpen: boolean;
};

const navigationContext = React.createContext<NavigationContextType | null>(null);
const treeKeyContext = React.createContext<KeyContextType>({ key: "", defaultOpen: true });

const NavigationProvider = ({ children, id }: { children: React.ReactNode; id?: string }) => {
  const [openState, setOpenState] = React.useState<Record<string, boolean>>(
    id ? JSON.parse(localStorage.getItem(id) ?? "{}") : {}
  );

  const isOpen = (key: string) => openState[key];

  const setOpen = (key: string, value: boolean) => {
    setOpenState((state) => {
      const newState = { ...state, [key]: value };
      if (id) {
        localStorage.setItem(id, JSON.stringify(newState));
      }
      return newState;
    });
  };

  return <navigationContext.Provider value={{ isOpen, setOpen }}>{children}</navigationContext.Provider>;
};

const KeyProvider = ({
  children,
  id,
  defaultOpen = false,
}: {
  children: React.ReactNode;
  id: string;
  defaultOpen?: boolean;
}) => {
  const parent = React.useContext(treeKeyContext);
  return (
    <treeKeyContext.Provider value={{ key: `${parent.key}.${id}`, defaultOpen }}>{children}</treeKeyContext.Provider>
  );
};

export const useNavigationOpen = () => {
  const keyCtx = React.useContext(treeKeyContext);
  const navCtx = React.useContext(navigationContext);
  if (!navCtx) {
    throw new Error("useNavigationOpen must be used within a NavigationProvider");
  }

  return [
    navCtx.isOpen(keyCtx.key) ?? keyCtx.defaultOpen,
    (isOpen: boolean) => navCtx.setOpen(keyCtx.key, isOpen),
  ] as const;
};

export const Navigation = ({
  id,
  className,
  children,
}: React.PropsWithChildren<{ id?: string; className?: string }>) => (
  <NavigationProvider id={id}>
    <KeyProvider id="root" defaultOpen>
      <NavigationInner className={className} children={children} />
    </KeyProvider>
  </NavigationProvider>
);

const NavigationInner = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useNavigationOpen();
  const PanelIcon = isOpen ? ChevronLeftIcon : ChevronRightIcon;

  return (
    <Collapsible.Root className={cn("relative flex flex-col h-full", className)} open={isOpen} onOpenChange={setIsOpen}>
      <Collapsible.Trigger asChild>
        <Button
          className="absolute top-2 right-0 p-0.5 translate-x-1/2 bg-dark border border-gray-600"
          cva={{ intent: "icon", size: "chip" }}
          title={isOpen ? "Collapse" : "Expand"}
        >
          <PanelIcon size={18} strokeWidth={1} />
        </Button>
      </Collapsible.Trigger>
      {children}
    </Collapsible.Root>
  );
};

export const NavigationContent = Collapsible.Content;

export const NavigationGroup = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <div className="flex flex-col">
    <div className="h-6 px-4">
      <Collapsible.Content className="uppercase text-sm text-gray-400 select-none">{title}</Collapsible.Content>
    </div>
    <KeyProvider id={title}>{children}</KeyProvider>
  </div>
);

export const NavigationItem = ({
  Icon,
  link,
  children,
  subItem,
}: {
  Icon?: React.FC<{ size?: string | number }>;
  link: string;
  children: React.ReactNode;
  subItem?: boolean;
}) => {
  const location = useLocation();
  const ariaCurrent = location.pathname === link ? "page" : undefined;

  return (
    <SimpleTooltip tooltipContent={children} side="right">
      <Link
        to={link}
        className={cn(
          "h-9 flex items-center gap-3 mx-2 px-2 my-0.5 hover:bg-slate-700 rounded-lg",
          "aria-[current]:text-highlightDeep aria-[current]:bg-highlightPale/25",
          "aria-[current]:hover:bg-highlightPale/50 [&:has([data-state='closed'])]:w-[34px]",
          subItem && "relative before:absolute before:bg-gray-600 before:top-4 before:-left-4 before:w-4 before:h-[1px]"
        )}
        aria-current={ariaCurrent}
      >
        {!subItem && Icon && <Icon size={18} />}
        <Collapsible.Content>{children}</Collapsible.Content>
      </Link>
    </SimpleTooltip>
  );
};

export const NavigationItemGroup = ({
  id,
  ...props
}: {
  Icon?: React.FC<{ size?: string | number }>;
  label: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  id: string;
}) => {
  return (
    <KeyProvider id={id}>
      <NavigationItemGroupInner {...props} />
    </KeyProvider>
  );
};

const NavigationItemGroupInner = ({
  Icon,
  label,
  children,
  className,
}: {
  Icon?: React.FC<{ size?: string | number }>;
  label: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}) => {
  const [isOpen, setIsOpen] = useNavigationOpen();
  const MenuIcon = isOpen ? ChevronDownIcon : ChevronRightIcon;

  return (
    <>
      <SimpleTooltip tooltipContent={label} side="right">
        <div
          role="button"
          className={cn(
            "h-9 flex items-center mx-2 px-2 my-0.5 rounded-lg hover:bg-slate-700 hover:cursor-pointer select-none",
            "[&:has([data-state='closed'])]:w-[34px]",
            className
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          {Icon && <Icon size={18} />}
          <Collapsible.Content className="grow flex justify-between items-center data-[state=open]:ml-3">
            {label}
            <MenuIcon size={18} />
          </Collapsible.Content>
        </div>
      </SimpleTooltip>
      <Collapsible.Content asChild>
        <Collapsible.Root open={isOpen} onOpenChange={setIsOpen} className="ml-8" asChild>
          <Collapsible.Content
            className={cn(
              "relative before:absolute before:bg-gray-600 before:-top-0.5 before:bottom-[21px] before:-left-2 before:w-[1px]"
            )}
          >
            {children}
          </Collapsible.Content>
        </Collapsible.Root>
      </Collapsible.Content>
    </>
  );
};

export const NavigationRoutes = <T,>({
  routes,
  url,
  className,
  ctx,
}: {
  routes: RouteObject<T>[];
  url: string;
  className?: string;
  ctx?: T;
}) => {
  const groups = _.groupBy(
    routes.filter((route) => route.handle && (route.handle.isApplicable?.(ctx) ?? true)),
    (route) => (route.handle as any).group
  );

  return (
    <div className={cn("flex flex-col py-2 gap-4 overflow-y-auto", className)}>
      {Object.entries(groups).map(([group, routes]) => (
        <NavigationGroup key={group} title={group}>
          <NavRoutes routes={routes} url={url} ctx={ctx} />
        </NavigationGroup>
      ))}
    </div>
  );
};

const NavRoutes = <T,>({
  routes,
  url,
  subItem,
  ctx,
}: {
  routes: RouteObject[];
  url: string;
  subItem?: boolean;
  ctx?: T;
}) => (
  <>
    {routes
      .filter((route) => route.handle && (route.handle.isApplicable?.(ctx) ?? true))
      .map((route) =>
        route.children ? (
          <NavigationItemGroup
            key={route.path}
            id={route.path!}
            Icon={route.handle!.icon}
            label={route.handle!.breadcrumb}
          >
            <NavRoutes routes={route.children} url={`${url}/${route.path}`} subItem={true} ctx={ctx} />
          </NavigationItemGroup>
        ) : (
          <NavigationItem key={route.path} Icon={route.handle!.icon} link={`${url}/${route.path}`} subItem={subItem}>
            {route.handle!.breadcrumb}
          </NavigationItem>
        )
      )}
  </>
);
