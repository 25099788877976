import { useMutation, useSuspenseQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { Label } from "@radix-ui/react-label";
import { FileText, FilePlus, Trash2, Copy, SearchIcon, PencilIcon } from "lucide-react";
import React, { PropsWithChildren, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { Input } from "@/DesignSystem/shadcn/Input";
import { ReportInput, gql } from "@/apis/nannyml";
import { Dialog, DialogContent, DialogFooter } from "@/components/Dialog";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/Table";
import { Button } from "@/components/common/Button";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";
import { useSearchList } from "@/hooks/useSearchList";
import { formatISODate, formatISODateTime } from "@/lib/dateUtils";

const copyReportMutation = gql(/* GraphQL */ `
  mutation CopyReport($reportId: Int!) {
    copy_report(reportId: $reportId) {
      id
      title
      creationDate
    }
  }
`);

const getReportsQuery = gql(/* GraphQL */ `
  query GetReports($filter: String) {
    reports(reportTitle: $filter) {
      id
      title
      creationDate
    }
  }
`);

const deleteReportMutation = gql(/* GraphQL */ `
  mutation DeleteReport($reportId: Int!) {
    delete_report(reportId: $reportId) {
      id
      title
      creationDate
    }
  }
`);

const createReportMutation = gql(/* GraphQL */ `
  mutation CreateReport($report: ReportInput!) {
    create_report(report: $report) {
      id
      title
      creationDate
    }
  }
`);

type ReportSummary = {
  id: number;
  title: string;
  creationDate: string;
};

type ReportsSummary = Extract<ResultOf<typeof getReportsQuery>, { __typename: "ReportsSummary" }>;

export const ManageReports = () => (
  <React.Suspense fallback={<RequestStateLayout isLoading={true} />}>
    <ManageReportsContainer />
  </React.Suspense>
);

const DeleteReportPopUp = ({
  report,
  open,
  onDeleteReport,
  closeDialog,
}: {
  report: ReportSummary;
  open: boolean;
  onDeleteReport: (reportId: number) => void;
  closeDialog: () => void;
}) => {
  return (
    <>
      {report ? (
        <Dialog open={open} onOpenChange={closeDialog}>
          <DialogContent className="max-w-fit max-h-[90%] overflow-auto">
            <Text className="m-3 p-2">Do you really want to permanently delete the report {report.title}?</Text>
            <div className="m-3 gap-3 flex justify-end">
              <Button onClick={closeDialog} cva={{ intent: "secondary", size: "medium" }}>
                Cancel
              </Button>
              <Button onClick={() => onDeleteReport(report.id)} cva={{ intent: "primary", size: "medium" }}>
                Confirm
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};

const ManageReportsContainer = ({ children }: PropsWithChildren) => {
  const { data, error } = useSuspenseQuery(getReportsQuery, {
    variables: { filter: "" },
  });

  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [reports, setReports] = useState(data.reports);

  const [saveReport, {}] = useMutation(createReportMutation);

  const [deleteReport, {}] = useMutation(deleteReportMutation);
  const [copyReport, {}] = useMutation(copyReportMutation);
  const [selectedReport, setSelectedReport] = useState<ReportSummary>();

  const { search, setSearch, results } = useSearchList(reports, "title");

  const sendDeleteRequest = (reportId: number) => {
    deleteReport({
      variables: { reportId: reportId },
    });

    setReports(reports.filter((report) => report.id !== reportId));
    setDialogOpen(false);
  };

  const sendCopyRequest = (reportId: number) => {
    copyReport({
      variables: { reportId: reportId },
    }).then(({ data }) => {
      setReports([{ ...data?.copy_report } as ReportSummary, ...reports]);
    });
  };

  const onClickDelete = (report: ReportSummary) => {
    setSelectedReport(report);
    setDialogOpen(true);
  };

  const createReport = () => {
    const title = {
      id: 0,
      label: "Title",
      args: ["text-2xl"],
      key: "ContentText",
      data: "New Report",
      textOptions: {
        placeholder: "Report Title",
        className: "text-2xl",
        cols: 100,
        rows: 1,
        iconSize: 20,
      },
    };
    saveReport({
      variables: {
        report: {
          title: title.data,
          content: JSON.stringify({ data: [title], metadata: {} }),
        } as ReportInput,
      },
    }).then(({ data }) => {
      if (data) {
        setReports([data?.create_report, ...reports]);
        navigate(`${data.create_report.id}`, { state: { editMode: true } });
      }
    });
  };

  return (
    <div>
      <div className=" gap-4 m-6">
        <div className="flex my-2 justify-between">
          <Label className="relative w-[300px] text-slate-400 cursor-text">
            <SearchIcon className="absolute left-3 top-3" size={16} />
            <Input
              className="pl-9 border-gray-600"
              placeholder="Search reports by title..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Label>
          <Button onClick={createReport} cva={{ intent: "primary", size: "small" }}>
            <div className="flex justify-center m-2">
              <FilePlus />
            </div>
            <div className="flexl">
              <label className="flex justify-center">Create new report</label>
            </div>
          </Button>
        </div>
        <div className="border rounded-md border-gray-600">
          <Table className="odd:[&_tr]:bg-oddBg">
            <TableHeader>
              <TableRow>
                <TableHead className="w-1/3">Report title</TableHead>
                <TableHead className="w-1/3">Creation date</TableHead>
                <TableHead className="w-full">Actions</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody className="w-full">
              {results.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} className="text-center text-gray-400 italic">
                    No metrics found
                  </TableCell>
                </TableRow>
              ) : (
                results.map((report) => {
                  return (
                    <TableRow key={report.id}>
                      <TableCell>
                        <Link
                          className="underline hover:text-blue-300 "
                          to={{ pathname: `${report.id}` }}
                          state={{ editMode: false }}
                        >
                          {report.title}
                        </Link>
                      </TableCell>
                      <TableCell>{formatISODateTime(new Date(report.creationDate))}</TableCell>
                      <TableCell>
                        <div className="flex">
                          <Button
                            className="mx-2"
                            cva={{ intent: "primary", size: "small" }}
                            title="View report"
                            onClick={() => navigate(`${report.id}`, { state: { editMode: true } })}
                          >
                            <PencilIcon />
                            Edit
                          </Button>

                          <Button
                            className="mx-2"
                            cva={{ intent: "secondary", size: "small" }}
                            title="Copy Report"
                            onClick={() => sendCopyRequest(report.id)}
                          >
                            <Copy />
                            Copy
                          </Button>
                          <Button
                            className="mx-2"
                            cva={{ intent: "reject", size: "small" }}
                            title="Delete report"
                            onClick={() => onClickDelete(report)}
                          >
                            <Trash2 />
                            Delete
                          </Button>
                        </div>
                      </TableCell>
                      <DeleteReportPopUp
                        onDeleteReport={sendDeleteRequest}
                        open={dialogOpen}
                        closeDialog={() => setDialogOpen(false)}
                        report={selectedReport!}
                      />
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};
