import Plotly from "plotly.js-dist-min";
import React, { useState } from "react";

import { padRange } from "@/adapters/monitoring";
import { TimeSeriesResult } from "@/apis/nannyml";
import { getResultTitles } from "@/formatters/monitoring";

import { ResultPlot, useDateFilterContext } from "../monitoring";
import { ContentData, PlotOptions, getPlotData } from "./Report.utils";
import { TextContent } from "./TextContent";

const PlotContent = ({
  content,
  onContentUpdate,
}: {
  content: ContentData;
  onContentUpdate: (content: ContentData) => void;
}) => {
  const { activeDateRange } = useDateFilterContext();
  const [minDate, maxDate] = React.useMemo(() => padRange(activeDateRange), [activeDateRange]);

  let standardDateRange = [new Date(minDate), new Date(maxDate)];

  const results = getPlotData(content.plotOptions!);

  const onUpdate = (figure: any, graphDiv: any) => {
    Plotly.toImage(graphDiv).then(function (url) {
      content.plotOptions!.plotImage = url;
    });
    // This is intended for individual plot zooming
    content.plotOptions!.layout = figure.layout;
  };

  if (content.plotOptions!.layout) {
    // This is intended to set up the slider date range zoom

    const [plotMinDate, plotMaxDate] = content.plotOptions!.layout.xaxis.range;

    standardDateRange = [
      new Date(minDate) > new Date(plotMinDate) ? new Date(minDate) : new Date(plotMinDate),
      new Date(maxDate) < new Date(plotMaxDate) ? new Date(maxDate) : new Date(plotMaxDate),
    ];

    content.plotOptions!.layout.xaxis.range = standardDateRange;
  }

  if (results) {
    const titles = getResultTitles({ ...results[0] } as TimeSeriesResult);
    content.plotOptions!.plotTitle = `${titles.title} ${titles.subtitle}`;
  }

  return (
    <>
      {results ? (
        <ResultPlot
          results={[results[0]]}
          onPlotUpdate={onUpdate}
          layout={content.plotOptions!.layout}
          dateRange={[standardDateRange[0], standardDateRange[1]]}
        />
      ) : null}
    </>
  );
};

export const Content = ({
  content,
  onContentUpdate,
  editMode,
}: {
  content: ContentData;
  onContentUpdate: (content: ContentData) => void;
  editMode: boolean;
}) => {
  return content.plotOptions ? (
    <>
      <PlotContent content={content} onContentUpdate={onContentUpdate} />
    </>
  ) : (
    <>
      <TextContent parentEditMode={editMode} content={content} onContentUpdate={onContentUpdate} />
    </>
  );
};
