import { useMutation, useSuspenseQuery } from "@apollo/client";
import { PDFDownloadLink } from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import { SaveIcon, PencilIcon, FileText, FileDown, Copy, Presentation } from "lucide-react";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Text } from "@/DesignSystem/basic/Text/Text";
import { EditReport, gql } from "@/apis/nannyml";
import { Dialog, DialogContent } from "@/components/Dialog";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/Popover";
import { PdfReport } from "@/components/Reporting/PdfReport";
import { PptxReport } from "@/components/Reporting/PptxReport";
import { Report } from "@/components/Reporting/Report";
import { ContentData } from "@/components/Reporting/Report.utils";
import { Button } from "@/components/common/Button";
import { RequestStateLayout } from "@/components/dashboard/RequestStateLayout/RequestStateLayout";
import { cn } from "@/lib/utils";

const copyReportMutation = gql(/* GraphQL */ `
  mutation CopyReport($reportId: Int!) {
    copy_report(reportId: $reportId) {
      id
      title
      creationDate
    }
  }
`);

const summaryReportFragment = gql(/* GraphQL */ `
  fragment newReport on Report {
    id
    creationDate
    title
  }
`);

const getReportInfo = gql(/* GraphQL */ `
  query GetReport($reportId: Int!) {
    get_report(reportId: $reportId) {
      title
      creationDate
      content
    }
  }
`);

const updateReportMutation = gql(/* GraphQL */ `
  mutation UpdateReport($report: EditReport!) {
    edit_report(report: $report) {
      id
      title
      creationDate
    }
  }
`);

export const Reporting = () => (
  <React.Suspense fallback={<RequestStateLayout isLoading={true} />}>
    <ReportingContainer />
  </React.Suspense>
);

export type ReportData = {
  title: ContentData;
  contents: ContentData[];
  metadata: Metadata;
};

type ReportContent = {
  data: ContentData[];
  metadata: Metadata;
};

export type Metadata = {
  dateRange?: [number, number];
};

const useReportId = () => {
  const reportId = useParams();
  return reportId["reportId"] && parseInt(reportId["reportId"]) ? parseInt(reportId["reportId"]) : 0;
};

const useContents = (reportId: number) => {
  const { data, error } = useSuspenseQuery(getReportInfo, {
    variables: { reportId: reportId },
  });

  const reportContent: ReportContent = JSON.parse(data.get_report.content);

  const contentData: ContentData[] = reportContent.data.map((content: any) => {
    return { ...(content as ContentData) };
  });

  const title = contentData.filter((c) => c.label === "Title");
  const contents = contentData.filter((c) => c.label !== "Title");

  return { title: title[0], contents: contents, metadata: reportContent.metadata } as ReportData;
};

const ExportReport = ({ contents }: { contents: ContentData[] }) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  return (
    <Popover open={isPopoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger className="items-center gap-3 flex">
        <FileDown />
        <span className="font-semibold">Export to</span>
      </PopoverTrigger>
      <PopoverContent align="start" className="text-slate-400 p-2 w-fit fcol">
        <PDFDownloadLink document={<PdfReport reportContent={contents} />} fileName="report.pdf">
          <Button>
            <FileText />
            PDF Version
          </Button>
        </PDFDownloadLink>
        <Button onClick={() => PptxReport(contents)}>
          <Presentation />
          PPTx Version
        </Button>
      </PopoverContent>
    </Popover>
  );
};

const ReportingContainer = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const [updateReport, {}] = useMutation(updateReportMutation, {
    update(cache, { data }) {
      if (data) {
        cache.modify({
          fields: {
            filter_reports() {
              cache.updateFragment(
                {
                  id: `Report:${data.edit_report.id}`,
                  fragment: summaryReportFragment,
                },
                (element) => {
                  return { ...element, ...data.edit_report };
                }
              );
            },
          },
        });
      }
    },
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pdfReady, setPdfReady] = useState(false);
  const reportId = useReportId();

  const reportContent = useContents(reportId);

  const [contents, setContents] = useState<ContentData[]>(reportContent.contents);
  const [title, setTitle] = useState<ContentData>(reportContent.title);
  const [metadata, setMetadata] = useState<Metadata>({});
  const [copyReport, {}] = useMutation(copyReportMutation);

  const [editMode, setEditMode] = useState(location.state.editMode);

  const saveContent = () => {
    setLoading(true);

    updateReport({
      variables: {
        report: {
          id: reportId,
          title: title.data,
          content: JSON.stringify({ data: [title, ...contents], metadata: metadata }),
        } as EditReport,
      },
    });

    setEditMode(false);
    setLoading(false);
  };

  const sendCopyRequest = (reportId: number) => {
    copyReport({
      variables: { reportId: reportId },
    }).then(({ data }) => {
      navigate(`../reports/${data?.copy_report.id}`, { state: { editMode: true } });
    });
  };

  return (
    <div className="flex flex-col h-full gap-1 p-4">
      <div id="graph" style={{ display: "none" }}></div>
      {loading ? (
        <RequestStateLayout isLoading={loading} />
      ) : (
        <div>
          <div className="flex flex-row justify-end">
            <div className="flex flex-row justify-end gap-2">
              {editMode ? (
                <Button
                  onClick={() => {
                    saveContent();
                  }}
                >
                  <SaveIcon />
                  Save
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      setEditMode(true);
                      setPdfReady(false);
                    }}
                  >
                    <PencilIcon />
                    Edit
                  </Button>
                  <div className="hover:bg-slate-900 py-3 px-5 text-base font-bold rounded">
                    <ExportReport contents={[title, ...contents]} />
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-row justify-end"></div>
          </div>
          <Report
            title={title}
            content={contents}
            metadata={metadata}
            onUpdateTitle={setTitle}
            onUpdateContentList={setContents}
            onUpdateMetadata={setMetadata}
            editable={editMode}
            reportDateRange={metadata.dateRange ? metadata.dateRange : undefined}
          />
        </div>
      )}
    </div>
  );
};
